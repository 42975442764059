import React from "react"
import { Link, graphql } from "gatsby"
import DefaultLayout from '../components/layout'

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  var nextChapter = ""
  return (
    <DefaultLayout>
      <div className="clearfix front-headers">
        <h2><a href="/authors/">Максим&nbsp;Гурбатов&nbsp;<em>и</em> Анна&nbsp;Чайковская</a></h2>
        <h1><a href="/">Книга Букв</a></h1>
        <h3><em>самый первый литерный проект</em></h3>
      </div>
      <div className="blog-posts contents incentered">
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
          var currentChapter = post.frontmatter.chapter_name
          return (
                <>
                {currentChapter == nextChapter ? 
                "" : 
                <h3>{!post.frontmatter.chapter_announce && <em>Глава {post.frontmatter.chapter}. </em>}{nextChapter=currentChapter}</h3>}
                <a href={post.fields.slug} dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />
                </>
                )
          })
        }
    </div>
    </DefaultLayout>
  )
}
export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            chapter
            chapter_name
            chapter_announce
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`